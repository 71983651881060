import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

interface InitialStateProps {
  recordFlexPane: boolean;
  startDate: string;
  endDate: string;
  selectedAttendanceRecordId: number | null;
  selectedAttendanceRecordDate: string | null;
  selectedRequestStatus: 'pending' | 'history';
}

const initialState: InitialStateProps = {
  recordFlexPane: false,
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  selectedAttendanceRecordId: 0,
  selectedAttendanceRecordDate: null,
  selectedRequestStatus: 'pending',
};

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    openRecordFlexPane: (state) => {
      state.recordFlexPane = true;
    },
    closeRecordFlexPane: (state) => {
      state.recordFlexPane = false;
    },
    setStartDate: (state, action) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.startDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid start date:', action.payload);
        state.startDate = format(new Date(), 'yyyy-MM-dd');
      }
    },
    setEndDate: (state, action) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.endDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid end date:', action.payload);
        state.endDate = format(new Date(), 'yyyy-MM-dd');
      }
    },
    setSelectedAttendanceRecordId: (state, action) => {
      state.selectedAttendanceRecordId = action.payload;
    },
    setSelectedAttendanceRecordDate: (state, action) => {
      state.selectedAttendanceRecordDate = action.payload;
    },
    setSelectedRequestStatus: (state, action) => {
      state.selectedRequestStatus = action.payload;
    },
  },
});

export const {
  openRecordFlexPane,
  closeRecordFlexPane,
  setStartDate,
  setEndDate,
  setSelectedAttendanceRecordId,
  setSelectedAttendanceRecordDate,
  setSelectedRequestStatus,
} = timesheetSlice.actions;
export default timesheetSlice.reducer;
