import { createSlice } from '@reduxjs/toolkit';

interface initialState {
  selectedMember: number | null;
  memberDetailFlexPane: boolean;
  teamDetailFlexPane: boolean;
}

const initialState: initialState = {
  selectedMember: null,
  memberDetailFlexPane: false,
  teamDetailFlexPane: false,
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setMemberDetailFlexPane: (state, action) => {
      state.memberDetailFlexPane = action.payload;
    },
    setTeamDetailFlexPane: (state, action) => {
      state.teamDetailFlexPane = action.payload;
    },
  },
});

export const {
  setSelectedMember,
  setMemberDetailFlexPane,
  setTeamDetailFlexPane,
} = memberSlice.actions;
export default memberSlice.reducer;
