import { createSlice } from '@reduxjs/toolkit';
import { DATE_RANGE } from '@/app/[lang]/app/[groupId]/report/Utils/constant';

const initialState = {
  menu: false,
  notification: 'default',
  drawer: false,
  defaultReportPeriod: DATE_RANGE.ONE_MONTH,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMenuToggle: (state, action) => {
      state.menu = !action.payload;
    },
    setNotificationToggle: (state, action) => {
      state.notification = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawer = true;
    },
    setDrawerClose: (state) => {
      state.drawer = false;
    },
    setDrawerToggle: (state) => {
      state.drawer = !state.drawer;
    },
    setDefaultReportPeriod: (state, action) => {
      state.defaultReportPeriod = action.payload;
    },
  },
});

export const {
  setMenuToggle,
  setDrawerOpen,
  setDrawerClose,
  setDrawerToggle,
  setNotificationToggle,
  setDefaultReportPeriod,
} = appSlice.actions;

export default appSlice.reducer;
