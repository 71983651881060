import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import {
  BudgetCodeId,
  DeleteBudgetRequestRequest,
  DeleteBudgetRequestResponse,
  DuplicateBudgetRequestApiRequest,
  GetBudgetCodeDetailRequest,
  GetBudgetCodeDetailResponse,
  GetBudgetCodeHistoryResponse,
  GetBudgetCodeReportResponse,
  GetBudgetRequestDetailRequest,
  GetBudgetRequestDetailResponse,
  GetOverviewRequest,
  GetOverviewResponse,
  QueryBudgetCodeByFilterReturnPercentageViewModelResponse,
  QueryBudgetRequestByFilterRequest,
  QueryBudgetRequestByFilterResponse,
  UpdateProjectBudgetRequest,
  UpdateProjectBudgetResponse,
} from './interface';

export const budgetApi = createApi({
  reducerPath: 'budgetApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Budget'],
  endpoints: (builder) => ({
    GetCategories: builder.query<any, any>({
      query: () => ({
        url: '/api/Budget/GetCategories',
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateProjectBudget: builder.mutation<
      UpdateProjectBudgetResponse,
      UpdateProjectBudgetRequest
    >({
      query: ({
        projectId,
        manpowerInternalBudget,
        manpowerExternalBudget,
        equipmentOwnedBudget,
        equipmentRentalBudget,
      }) => ({
        url: '/api/Budget/UpdateProjectBudget',
        method: 'PUT',
        data: {
          projectId,
          manpowerInternalBudget,
          manpowerExternalBudget,
          equipmentOwnedBudget,
          equipmentRentalBudget,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetOverview: builder.query<GetOverviewResponse, GetOverviewRequest>({
      query: ({ projectId }) => ({
        url: '/api/Budget/GetOverview',
        method: 'GET',
        params: { projectId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetBudgetCodeReport: builder.query<
      GetBudgetCodeReportResponse,
      BudgetCodeId
    >({
      query: (budgetCodeId) => ({
        url: '/api/Budget/GetBudgetCodeReport',
        method: 'GET',
        params: { budgetCodeId: budgetCodeId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetBudgetCodeDetail: builder.query<
      GetBudgetCodeDetailResponse,
      GetBudgetCodeDetailRequest
    >({
      query: ({ budgetCodeId }) => ({
        url: '/api/Budget/GetBudgetCodeDetail',
        method: 'GET',
        params: { budgetCodeId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    GetBudgetCodeHistory: builder.query<
      GetBudgetCodeHistoryResponse[],
      BudgetCodeId
    >({
      query: (budgetCodeId) => ({
        url: '/api/Budget/GetBudgetCodeHistory',
        method: 'GET',
        params: { budgetCodeId: budgetCodeId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetBudgetCodeHistoryListDetail: builder.query<any, any>({
      query: () => ({
        url: '/api/Budget/GetBudgetCodeHistoryListDetail',
        method: 'GET',
        params: {},
      }),
      transformResponse: (response) => response.data.data,
    }),
    QueryBudgetCodeByFilter: builder.query<any, any>({
      query: () => ({
        url: '/api/Budget/QueryBudgetCodeByFilter',
        method: 'GET',
        params: {},
      }),
      transformResponse: (response) => response.data.data,
    }),
    QueryBudgetCodeByFilterReturnPercentageViewModel: builder.query<
      QueryBudgetCodeByFilterReturnPercentageViewModelResponse[],
      number
    >({
      query: (projectId) => ({
        url: '/api/Budget/QueryBudgetCodeByFilterReturnPercentageViewModel',
        method: 'GET',
        params: {
          projectId: projectId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    CreateBudgetCode: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Budget/CreateBudgetCode',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['Budget'],
    }),
    UpdateBudgetCode: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Budget/UpdateBudgetCode',
        method: 'PUT',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    ArchivedBudgetCode: builder.mutation<any, any>({
      query: ({ budgetCodeId }) => ({
        url: '/api/Budget/ArchivedBudgetCode',
        method: 'PUT',
        data: { budgetCodeId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    DeleteBudgetCode: builder.mutation<any, any>({
      query: ({ budgetCodeId }) => ({
        url: '/api/Budget/DeleteBudgetCode',
        method: 'DELETE',
        data: { budgetCodeId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetBudgetRequestDetail: builder.query<
      GetBudgetRequestDetailResponse,
      GetBudgetRequestDetailRequest
    >({
      query: ({ budgetRequestId }) => ({
        url: '/api/Budget/GetBudgetRequestDetail',
        method: 'GET',
        params: { budgetRequestId: budgetRequestId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    QueryBudgetRequestByFilter: builder.mutation<
      QueryBudgetRequestByFilterResponse[],
      QueryBudgetRequestByFilterRequest
    >({
      query: (data) => ({
        url: '/api/Budget/QueryBudgetRequestByFilter',
        method: 'POST',
        data: data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    QueryBudgetRequestByFilterReturnGrouped: builder.mutation<any, any>({
      query: () => ({
        url: '/api/Budget/QueryBudgetRequestByFilterReturnGrouped',
        method: 'POST',
        data: {},
      }),
      transformResponse: (response) => response.data.data,
    }),
    DuplicateBudgetRequest: builder.mutation<
      number,
      DuplicateBudgetRequestApiRequest
    >({
      query: (data) => ({
        url: '/api/Budget/DuplicateBudgetRequest',
        method: 'POST',
        data: data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    CreateBudgetRequest: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Budget/CreateBudgetRequest',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateBudgetRequest: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Budget/UpdateBudgetRequest',
        method: 'PUT',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    ReplyBudgetRequest: builder.mutation<any, any>({
      query: ({ budgetRequestId, answer }) => ({
        url: '/api/Budget/ReplyBudgetRequest',
        method: 'POST',
        data: {
          budgetRequestId,
          answer,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    DeleteBudgetRequest: builder.mutation<
      DeleteBudgetRequestResponse,
      DeleteBudgetRequestRequest
    >({
      query: ({ projectId, budgetRequestId }) => ({
        url: '/api/Budget/DeleteBudgetRequest',
        method: 'DELETE',
        data: {
          projectId: projectId,
          budgetRequestId: budgetRequestId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useUpdateProjectBudgetMutation,
  useGetOverviewQuery,
  useGetBudgetCodeReportQuery,
  useGetBudgetCodeDetailQuery,
  useGetBudgetCodeHistoryQuery,
  useGetBudgetCodeHistoryListDetailQuery,
  useQueryBudgetCodeByFilterQuery,
  useQueryBudgetCodeByFilterReturnPercentageViewModelQuery,
  useCreateBudgetCodeMutation,
  useUpdateBudgetCodeMutation,
  useArchivedBudgetCodeMutation,
  useDeleteBudgetCodeMutation,
  useGetBudgetRequestDetailQuery,
  useQueryBudgetRequestByFilterMutation,
  useQueryBudgetRequestByFilterReturnGroupedMutation,
  useDuplicateBudgetRequestMutation,
  useCreateBudgetRequestMutation,
  useUpdateBudgetRequestMutation,
  useReplyBudgetRequestMutation,
  useDeleteBudgetRequestMutation,
} = budgetApi;
